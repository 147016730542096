<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Downloads category</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Downloads category
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createDownloadCategory()"
                      class="mt-1 btn btn-block btn-primary"
                      style="color:#fff;"
                      :loading="loading"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                   Add new
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Name"
                      v-model="search.name"
                      v-on:keyup.enter="searchDownloadCategory"
                      @input="search.name = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3" v-if="currentUser.access_type == 'federal'">
                  <v-select
                      :items="scores"
                      v-model="search.score_id"
                      v-on:keyup.enter="searchDownloadCategory"
                      @input="search.score_id = $event !== null ? $event : ''"
                      label="SCORE"
                      item-text="name"
                      item-value="id"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-select
                      label="Status"
                      v-model="search.is_active"
                      v-on:keyup.enter="searchDownloadCategory"
                      @input="search.score_id = $event !== null ? $event : ''"
                      item-text="name"
                      item-value="value"
                      :items="status"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" :md="currentUser.access_type == 'score' ? 6 : 3" class="text-right">
                  <v-btn
                      @click.prevent="searchDownloadCategory"
                      class="mt-1 btn btn-primary"
                      style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Name</th>
                  <th class="px-3">Position</th>
                  <th class="px-3">Users</th>
                  <th class="px-3">SCORE</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>

                <template>
                  <draggable class="w-100" v-model="downloadCategories" @change="sort" @start="drag=true" tag="tbody" @end="drag=false">
                    <tr v-for="(downloadCategory, index) in downloadCategories" :key="index">
                      <td class="px-3">
                        <a @click="editDownloadCategory(downloadCategory)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                          <i class="fa fa-sort"></i> {{ downloadCategory.name }}
                        </a>
                      </td>
                      <td class="px-3">
                        {{ downloadCategory.position }}
                      </td>
                      <td class="px-3">
                        <ul v-if="downloadCategory.applicable_users_lists">
                          <li v-for="(user,index) in downloadCategory.applicable_users_lists" :key="index">
                            {{ user }}
                          </li>
                        </ul>
                        <span class="font-weight-bolder text-dark-65" v-else>
                          NA
                        </span>
                      </td>

                      <td class="px-3">
                        {{ downloadCategory.score ? downloadCategory.score.name : '--' }}
                      </td>

                      <td class="px-3">
                        <span class="badge"
                              v-bind:class="{ 'badge-success': downloadCategory.is_active, 'badge-danger': !downloadCategory.is_active }"
                        >{{ downloadCategory.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>

                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="editDownloadCategory(downloadCategory)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteDownloadCategory(downloadCategory.id)">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="downloadCategories.length == 0">
                      <td class="text-center font-weight-bold px-3" colspan="6">No Data Found</td>
                    </tr>
                  </draggable>
                </template>

              </table>
              <b-pagination
                  v-if="downloadCategories.length > 0"
                  class="pull-right mt-7"
                  @input="getAllDownloadCategories"
                  v-model="page"
                  :disabled="loading"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
            <!-- table ends -->
            <v-overlay :value="isSorting">
              <v-progress-circular
                  indeterminate
                  color="dark"
              ></v-progress-circular>
            </v-overlay>
          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllDownloadCategories"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "@/view/pages/view/cms/download-category/CreateAndUpdate";
import DownloadCategoryService from "@/services/cms/download-category/DownloadCategoryService";
import ScoreService from "@/services/score/score/ScoreService";
import draggable from "vuedraggable";

const downloadCategory = new DownloadCategoryService();
const score = new ScoreService();

export default {
  components: {
    CreateAndUpdate,
    draggable
  },
  data() {
    return{
      loading: false,
      isSorting: false,
      downloadCategories: [],
      scores: [],
      currentUser: {},
      total: null,
      perPage: null,
      page: null,
      status: [
        { name: 'Active', value: '1'},
        { name: 'Inactive', value: '0'},
      ],
      search: {
        name:'',
        score_id: '',
        is_active:'',
      }
    }
  },
  mounted() {
    this.getAllDownloadCategories();
    this.getAllScores();
    this.getCurrentUser();
  },
  methods: {
    createDownloadCategory() {
      this.$refs['create-and-update'].createDownloadCategory();
    },
    editDownloadCategory(downloadCategory) {
      this.$refs['create-and-update'].editDownloadCategory(downloadCategory);
    },
    getAllDownloadCategories() {
      this.loading = true;
      downloadCategory
      .paginate(this.search,this.page)
      .then(response => {
        this.downloadCategories = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.loading = false;
      })
          .catch((err) => {
            this.loading = false;
          });
    },
    getAllScores() {
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    sort(){
      this.isSorting = true;
      downloadCategory
          .sort(this.downloadCategories)
          .then(response => {
            this.isSorting = false;
            this.$snotify.success('Download Category Sorted !!');
            this.getAllDownloadCategories();
          })
          .catch(err => {
            this.isSorting = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    searchDownloadCategory() {
      this.getAllDownloadCategories();
    },
    deleteDownloadCategory(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            downloadCategory
                .delete(id)
                .then((response) => {
                  this.getAllDownloadCategories()
                  this.$snotify.success("Download category deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    }
  },
}
</script>